<template>
  <b-container>
    <b-row align-v="center" align-h="center" style="height: 100vh">
      <b-col cols="6">
        <b-card>
          <b-img
            src="../assets/logo.png"
            alt="Responsive image"
            width="150"
            fluid
            center
          />

          <b-card-text>
            <b-form @submit="onSubmitPass">
              <form-input
                v-model="form.newPassword"
                label="Senha:"
                placeholder="Digite sua nova senha"
                type="password"
                required
              />

              <b-button type="submit" block variant="primary">
                ENVIAR
              </b-button>
            </b-form>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions } from "vuex";
import FormInput from "../components/form/FormInput.vue";

export default {
  components: { FormInput },
  name: "ChangePassword",
  data() {
    return {
      form: {
        newPassword: "",
      },
    };
  },

  methods: {
    ...mapActions("auth", ["changePass"]),

    async onSubmitPass(event) {
      event.preventDefault();
      const { token } = this.$route.params;
      await this.changePass({ token, form: this.form });
    },
  },
};
</script>

<style></style>
